<template>
  <div
    class="h-sm-100 d-flex flex-column justify-content-between"
    id="homeNav"
    :style="bgColor"
  >
    <FlowerRight class="align-self-end" />
    <div class="align-self-center text-center">
      <span>The Wedding of</span>
      <h1
        data-aos="fade-down"
        data-aos-duration="1500"
        class="h1-home mt-3 mb-1"
      >
        {{ couple.female.nickname }} & {{ couple.male.nickname }}
      </h1>
      <span>{{ event.marriage.day }}, {{ event.marriage.date }}</span>
    </div>
    <FlowerLeft class="align-self-start" />
  </div>
</template>

<script>
const FlowerRight = () =>
  import("@/assets/illustrations/flower-home-bg-right.svg");
const FlowerLeft = () =>
  import("@/assets/illustrations/flower-home-bg-left.svg");

export default {
  name: "HomeSection",
  props: ["couple", "event", "colors", "theme"],
  components: { FlowerRight, FlowerLeft },
  computed: {
    bgColor() {
      return `background-color: rgb(${this.colors.color4}); color: rgb(${this.colors.primary});`;
    },
  },
};
</script>
